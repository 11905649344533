import axios from 'axios';

const API_KEY = process.env.REACT_APP_API_KEY;  // Ensure this is securely included in your build environment
const content_generator_core_host = process.env.REACT_APP_API_URL;

const api = axios.create({
    baseURL: content_generator_core_host,
    headers: {
        'X-API-KEY': API_KEY
    }
});

export default api;