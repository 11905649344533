import React, { useState, useRef, useEffect } from 'react';

import VideoCard from './VideoCard';
import Wizard from './Wizard';
import UserVideoList from './UserVideoList';

import MobileMessage from './MobileMessage'; // Import the MobileMessage component
import WordPullUp from "../@/components/magicui/word-pull-up";
import { BorderBeam } from "../@/components/magicui/border-beam";
import TermsModal from './TermsModal'; // Import the TermsModal component
import Marquee from "../@/components/magicui/marquee";
import Header from './Header'; // Import new Header
import { onAuthStateChanged } from 'firebase/auth';
import { auth } from '../services/UserService'; // Firebase auth



function LandingPage() {
  const [user, setUser] = useState(null);
  const [text, setText] = useState('');
  const [validatedText, setValidatedText] = useState(''); // New state to hold validated script
  const [featuredVideoUrl, setFeaturedVideoUrl] = useState(''); // State to hold the featured video URL
  const [videoKey, setVideoKey] = useState(0); // Add a key state
  const [error, setError] = useState('');
  const isMobile = window.innerWidth <= 768;
  const [isTermsModalOpen, setIsTermsModalOpen] = useState(false); // State for terms modal
  const closeTermsModal = () => setIsTermsModalOpen(false); // Close terms modal
  const [wizardKey, setWizardKey] = useState(0); // Key to force re-render Wizard component
  const [selectedVideo, setSelectedVideo] = useState(null);
  const featuredVideoRef = useRef(null);
  const wizardRef = useRef(null);

  // Listen for authentication state changes
  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, (user) => {
      if (user) {
        setUser(user); // Set logged-in user
      } else {
        setUser(null); // No user is logged in
      }
    });

    return () => unsubscribe(); // Cleanup the listener on unmount
  }, []);

  const featuredVideos = [
    { id: 1, url: 'https://storage.googleapis.com/reelfy-media-assets/featured-clips/The%20enhancement%20dilemma.mp4', poster: 'https://storage.googleapis.com/reelfy-media-assets/users/661e2386eff8eaacc86c2449/videos/666332d5b8800e9dbf76addf/images/1.png' },
    { id: 2, url: 'https://storage.googleapis.com/reelfy-media-assets/users/661e2386eff8eaacc86c2449/videos/6668aecaa784c39a6c54c51a/final_clip.mp4', poster: 'https://storage.googleapis.com/reelfy-media-assets/users/661e2386eff8eaacc86c2449/videos/6668aecaa784c39a6c54c51a/images/1.png' },
    { id: 3, url: 'https://storage.googleapis.com/reelfy-media-assets/users/661e2386eff8eaacc86c2449/videos/6668b42ba784c39a6c54c51e/final_clip.mp4', poster: 'https://storage.googleapis.com/reelfy-media-assets/users/661e2386eff8eaacc86c2449/videos/6668b42ba784c39a6c54c51e/images/1.png' }
    // more videos
];


  // Sample stories
  const sampleStories = [
  "Early morning at a small village. The sun peeks over the horizon, casting golden light on the thatched roofs of the cottages. A young girl named Lily steps outside, her backpack ready for an adventure.",
  "Tom discovers a small pond in the heart of the garden. The water reflects the sky above, and lily pads float gently on the surface. Dragonflies buzz around, their wings glinting in the sunlight.",
  "Bright lights illuminate the carnival grounds. Children and families walk between booths, their faces lit with excitement. The Ferris wheel turns slowly against the night sky.\n\nEmma, a young woman with a bright smile, tries her luck at a ring toss game. Colorful rings fly through the air, aiming for wooden pegs."];

  const handleVideoSelect = (url) => {
    setFeaturedVideoUrl(url);
    setVideoKey(prevKey => prevKey + 1);

    setTimeout(() => {
      if (featuredVideoRef.current) {
        featuredVideoRef.current.scrollIntoView({ behavior: 'smooth' });
      }
    }, 100); // Adjust delay as necessary
  };

    // Function to load story into main text area
  const loadStory = (story) => {
    setText(story);
  };

  const handleSubmitScript = () => {
    if (!text.trim()) {
      setError('Do not forget to write down your script!');
      return;
    }
    setError('');
    setValidatedText(text);
    setWizardKey(prevKey => prevKey + 1); // Change the key to force re-render
    if (wizardRef.current) {
      wizardRef.current.scrollIntoView({ behavior: 'smooth' });
    }
  };

  const handleUserVideoSelect = (video) => {
    setSelectedVideo({ ...video, key: Date.now() }); // Add unique key
  };

  return (
    <div className="landing-page">

      {isMobile && <MobileMessage />}

      {/* Header */}
      <Header user={user} />

      <div className="flex w-full">
        {/* Sidebar for user videos */}
        {user && (
            <aside className="w-1/4 min-h-[600px] p-4 bg-gray-200 rounded-lg shadow-lg mr-1">
              <UserVideoList user={user} onVideoSelect={handleUserVideoSelect}/>
            </aside>
        )}

        {/* Main Content Area */}
        <main className={`${user ? 'w-3/4' : 'w-full'} main-content p-8 flex-grow`}>
          <div className="intro text-center mb-16">
            <h2 className="text-5xl mb-4 font-bold mt-8">Script to Video</h2> {/* Added margin-top */}
            <WordPullUp
                className="intro-subtitle text-xl font-medium my-6"
                words="Let's amplify together your message, drop that script in the box, and let Reelfy create the best audiovisual match for your craft"
            />
            <div className="relative-container">
                    <textarea
                        value={text}
                        onChange={(e) => setText(e.target.value)}
                        rows="5"
                        placeholder="Write your script here..."
                        className="script-input w-full p-4 border border-gray-300 rounded-lg"
                    />
              <BorderBeam borderWidth="2.5"/>
            </div>
            {error && <p className="text-red-500 mt-2">{error}</p>} {/* Error message here */}
            <button className="mt-4 px-8 py-2 rounded-full bg-[#463ca7] text-white" onClick={handleSubmitScript}>
              Submit
            </button>
            <p className="mt-4 text-sm text-gray-500">
              By generating a video, you agree to our <span className="text-blue-500 cursor-pointer"
                                                            onClick={() => setIsTermsModalOpen(true)}>Terms of Service</span>.
            </p>
          </div>

          <section className="inspiration text-center mb-16">
            <h3 className="text-2xl font-bold mb-4">Looking for inspiration?</h3>
            <div className="story-grid flex justify-center gap-4">
              {sampleStories.map((story, index) => (
                  <textarea
                      key={index}
                      value={story}
                      readOnly
                      onClick={() => loadStory(story)}
                      className="story-card w-1/3 p-4 border border-gray-300 rounded-lg cursor-pointer"
                      rows="4"
                  />
              ))}
            </div>
          </section>

          {/* Wizard Container */}
          <Wizard key={wizardKey} text={validatedText} selectedVideo={selectedVideo}/>

          {/* This is the featured videos container */}
          <div
              className="relative flex flex-col items-center justify-center overflow-hidden rounded-lg border bg-background md:shadow-xl">
            <h2 className="text-3xl mb-4">Featured Videos</h2>
            <Marquee pauseOnHover className="[--duration:20s]">
              {featuredVideos.map((video) => (
                  <VideoCard key={video.id} {...video} onClick={() => handleVideoSelect(video.url)}/>
              ))}
            </Marquee>
            <div
                className="pointer-events-none absolute inset-y-0 left-0 w-1/3 bg-gradient-to-r from-white dark:from-background"></div>
            <div
                className="pointer-events-none absolute inset-y-0 right-0 w-1/3 bg-gradient-to-l from-white dark:from-background"></div>
          </div>

          {featuredVideoUrl && (
              <div ref={featuredVideoRef} style={{marginTop: '20px', textAlign: 'center'}}>
                <video key={videoKey} controls autoPlay style={{maxHeight: '500px', maxWidth: '90%', margin: '0 auto'}}>
                  <source src={featuredVideoUrl} type="video/mp4"/>
                  Your browser does not support the video tag.
                </video>
              </div>
          )}
        </main>
      </div>
      {/* Roadmap Section */}
      <section className="image-section mt-12">
        <img className="default-image responsive-image" src="/mission_vision_roadmap.png" alt="Mission, Vision, and Roadmap" />
        <img className="mobile-image responsive-image" src="/1.png" alt="Mission - Mobile View" />
        <img className="mobile-image responsive-image" src="/2.png" alt="Vision - Mobile View" />
      </section>

      {/* Footer */}
      <footer className="footer py-6 bg-[#463ca7] text-white text-center text-base font-normal">
        <p>&copy; 2024 Reelfy. All rights reserved.</p>
      </footer>

      <TermsModal
        isOpen={isTermsModalOpen}
        onClose={closeTermsModal}
      />

    </div>
  );
}

export default LandingPage;
