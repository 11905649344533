import api from './AxiosConfig';

export const getScenes = (videoId, modelId, onSceneReceived) => {
  const ws = new WebSocket(`${process.env.REACT_APP_WS_API_URL}/v1/film-wizard/video/${videoId}/scenes`);

  // Open WebSocket connection and send the initial payload to start generating scenes
  ws.onopen = () => {
    ws.send(JSON.stringify({
      "model_id": modelId
    }));
  };

  // Listen for messages containing scene data and pass each scene to the callback
  ws.onmessage = (event) => {
    const data = JSON.parse(event.data);

    if (data.status === "error") {
      console.error('Error in WebSocket:', data.detail);
      ws.close();
      return;
    }

    if (data.video && data.video.scenes) {
      onSceneReceived(data.video); // Pass the entire video object to handle both initial and progressive updates
    }
  };

  // Handle any errors that may occur
  ws.onerror = (error) => {
    console.error('WebSocket error:', error);
  };

  // Close the WebSocket connection when the component unmounts
  return () => {
    ws.close();
  };
};

export const getScene = async (videoId, sceneId, modelId) => {
  try {
    const response = await api.put(`/v1/film-wizard/video/${videoId}/scene`, {
      "scene_index": sceneId,
      "model_id": modelId
    });
    return response.data;
  } catch (error) {
    console.error('Error in fetchVideoUrl:', error);
    return null;
  }
};

export const generateFinalClip = async (videoId) => {
  try {
    const response = await api.post(`/v1/film-wizard/video/${videoId}`);
    return response.data;
  } catch (error) {
    console.error('Error in fetchVideoUrl:', error);
    return null;
  }
};