import { getAuth, signInWithPopup, GoogleAuthProvider, signOut } from "firebase/auth";
import { initializeApp } from "firebase/app";
import api from './AxiosConfig';

const firebaseConfig = {
  apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
  authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
  projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
  storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
  appId: process.env.REACT_APP_FIREBASE_APP_ID,
  measurementId: process.env.REACT_APP_MEASUREMENT_ID
};

const app = initializeApp(firebaseConfig);
export const auth = getAuth(app);

/**
 * Login the user with Google and store the Firebase UID in the backend.
 */
export const loginAndStoreUser = async (videoId) => {
  const provider = new GoogleAuthProvider();
  try {
    const result = await signInWithPopup(auth, provider);
    const loggedUser = result.user;
    const firebaseUid = loggedUser.uid;

    // Store Firebase UID in the backend
    await storeUserInBackend({
      firebaseUid,
      name: loggedUser.displayName,
      email: loggedUser.email,
      videoId, // Send videoId as well
    });

    return firebaseUid;
  } catch (error) {
    console.error("Error during login:", error);
    throw new Error("Login failed");
  }
};

/**
 * Get authenticated user ID
 */
export const getAuthenticatedUserId = async () => {
  const user = auth.currentUser;
  if (user) {
    return user.uid;
  }
};

/**
 * Log the user out from Firebase authentication
 */
export const logoutUser = async () => {
  try {
    await signOut(auth);
    console.log("User logged out successfully");
  } catch (error) {
    console.error("Error during logout:", error);
    throw new Error("Logout failed");
  }
};

export const fetchUserVideos = async (userId) => {
  try {
    const response = await api.get(`/v1/user/${userId}/videos`);
    return response.data.videos; // Assuming the response contains a 'videos' array
  } catch (error) {
    console.error("Error fetching user videos:", error);
    throw error;
  }
};

/**
 * Store the Firebase UID in the backend for further usage
 */
const storeUserInBackend = async ({ firebaseUid, name, email, videoId }) => {
  try {
    const response = await api.post('v1/user/login', {
      firebase_uid: firebaseUid,
      name: name,
      email: email,
      video_id: videoId,
    });

    if (response.status !== 200) {
      throw new Error('Failed to store user in the backend');
    }

    return response.data;
  } catch (error) {
    console.error("Error storing user in backend:", error);
    throw new Error("Failed to store user in backend");
  }
};