import api from './AxiosConfig';


export const getModels = async (text) => {
  try {
      const response = await api.get(`/v1/film-wizard/models`);
      return response.data.models;
  } catch (error) {
    console.error('Error in fetchVideoUrl:', error);
    return null;
  }
};
