import React from 'react';
import { FaTimes } from 'react-icons/fa'; // Import the close icon
import './TermsModal.css'; // Import the CSS file for styling

const TermsModal = ({ isOpen, onClose }) => {
  if (!isOpen) return null;

  return (
    <div className="terms-modal-overlay" onClick={onClose}>
      <div className="terms-modal" onClick={(e) => e.stopPropagation()}>
        <div className="terms-modal-header">
          <h3>Content Policy for AI Tools</h3>
          <button className="close-btn" onClick={onClose}><FaTimes /></button>
        </div>
        <div className="terms-modal-body">
          <p>As we bring more generative AI to REELFY, we want to be clear about the acceptable use of these exciting new capabilities. Our goal is to provide creative tools that enhance your video editing experience while ensuring a safe and respectful environment for all content consumers.</p>
          <p>You agree not to use the AI Tools to create any harmful content. We will not allow any use of our AI Tools that violate these terms or Veed Limited’s Terms, and we may suspend or terminate your account if we find that you are using it in this way. In particular, do not use REELFY’s AI Tools:</p>
          <ul>
            <li>In any way that violates or promotes activities that violate any applicable laws or regulations (including ways that violate anyone’s intellectual property or privacy rights);</li>
            <li>To exploit, harm, or attempt to sexualize minors in any way;</li>
            <li>To generate or disseminate false or misleading information and propaganda (including attempts to create images of public figures);</li>
            <li>To generate or distribute personally identifiable information;</li>
            <li>To defame, disparage, or otherwise harass others;</li>
            <li>For fully automated decision-making that adversely impacts an individual’s legal rights or otherwise creates or modifies a binding, enforceable obligation;</li>
            <li>For any use intended to, or which has the effect of, discriminating against or harming any individuals or groups;</li>
            <li>To exploit any of the vulnerabilities of a specific group of persons based on any characteristics, including but not limited to their age, social, physical, or mental characteristics;</li>
            <li>To provide medical advice and/or interpretation of medical results;</li>
            <li>To promote, glorify, encourage, or create a risk of hate, violence, harm to people or property, or self-harm;</li>
            <li>To create content attempting to influence political processes and content used for campaigning purposes;</li>
            <li>To create sexually explicit or pornographic material; or</li>
            <li>To generate or disseminate information to administer justice, law enforcement, immigration, or asylum processes.</li>
          </ul>
          <h2>Additional Prohibited Uses Specifically for AI Avatars and TTS Features:</h2>
          <p>Impersonating any person or entity using AI avatars or the TTS feature is not allowed.</p>
          <p>Portraying AI avatars in user-generated content or using the TTS feature in a way that would reasonably be found offensive, such as depicting them as suffering from medical conditions or associating them with regulated or age-inappropriate goods/services, is not allowed.</p>
          <p>Using AI avatars or the TTS feature in user-generated content to make statements about sensitive topics such as religion, politics, race, gender, or sexuality is strictly prohibited.</p>
          <h2>Legal notice</h2>
          <p>You are responsible for any images you create using REELFY’s AI Tools. As between you and Veed Limited, to the extent permitted by law, you own your AI images and give Veed Limited the right to host them on our platform and use them to market and improve our products and services. REELFY will not make any copyright ownership claims over your AI Images.</p>
          <p>You may use your AI Images for any legal purpose, provided that you comply with these terms and accept that any such use is at your own risk. Veed Limited does not warrant, guarantee, or assume liability for any uses you make of AI Images.</p>
          <p>When using AI Images in your REELFY Limited designs, we ask that you let viewers of your designs know that the content is AI-generated.</p>
          <p>In the event that any of your AI Images are alleged to be unlawful or otherwise in breach of these terms, you acknowledge that Veed Limited may disclose such content to law enforcement or other governmental authorities.</p>
          <p>We reserve the right to update these terms as we deem necessary.</p>
        </div>
      </div>
    </div>
  );
};

export default TermsModal;