import React from 'react';
import { FaGoogle } from 'react-icons/fa';
import './LoginModal.css';

const LoginModal = ({ isOpen, onClose, onLogin }) => {
    if (!isOpen) return null;

    return (
        <div className="modal-overlay">
            <div className="modal-container">
                <h3 className="modal-title">One More Step to Continue Your Creation</h3>
                <p className="modal-message">
                    You're just a step away from continuing with your artistic project! At Reelfy, we’re committed to empowering creators like you.
                    By signing in, you’ll be able to save and enhance your work, ensuring your creativity is preserved and can be revisited anytime.
                </p>
                <p className="modal-message">
                    Join us in bringing your vision to life – simply log in to proceed with your creation. We’re here to support you every step of the way.
                </p>
                <div className="button-group">
                    <button onClick={onClose} className="cancel-button">
                        Cancel
                    </button>
                    <button onClick={onLogin} className="login-button">
                        <FaGoogle className="google-icon" />
                        Login with Google
                    </button>
                </div>
            </div>
        </div>
    );
};

export default LoginModal;