import React from 'react';
import './MobileMessage.css'; // Create this CSS file for styling

const MobileMessage = () => {
  return (
    <div className="mobile-warning">
      <p>Our mobile version is still under construction. Please visit us on a desktop to make the visual version of your script come true!</p>
    </div>
  );
};

export default MobileMessage;