import React from 'react';
import './AestheticsSelector.css';

const AestheticsSelector = ({ aesthetics, onSelect }) => {
  return (
    <div className="aesthetics-selector">
      <h1>What aesthetics suits your film the best?</h1>
      <div className="aesthetics-grid">
        {aesthetics.map((aesthetic, index) => (
          <div 
            key={index} 
            className="aesthetic-item" 
            onClick={() => onSelect(aesthetic)}
          >
            <h3>{aesthetic.name}</h3> {/* Add the name here */}
            <img src={aesthetic.provider.model_sample_image_url} alt={aesthetic.name} />
            <div className="aesthetic-info">
              <p>Powered by</p>
              <img 
                src={aesthetic.provider.logo_url} 
                alt="Company Logo" 
                className="company-logo" 
                style={{ width: '32px', height: '32px' }}
                />
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default AestheticsSelector;