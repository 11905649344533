import React, { useState, useEffect } from 'react';
import { FaSync } from 'react-icons/fa';
import { getScene } from '../../services/VideoService';
import './VideoViewer.css';

const VideoViewer = ({ scenes, onBack, onGenerateVideo, videoId, modelId, isWebSocket }) => {
    const [sceneData, setSceneData] = useState(Object.values(scenes));
    const [loadingScenes, setLoadingScenes] = useState(new Array(scenes.length).fill(false));

    useEffect(() => {
        if (!isWebSocket) {
            setSceneData(scenes); // Static data loading
        }
    }, [scenes, isWebSocket]);

    useEffect(() => {
        if (isWebSocket) {
            // Initialize with placeholders if scenes have placeholders without URLs
            if (scenes.length > 0 && scenes.every((scene) => scene.url === '')) {
                setSceneData(scenes);
            } else {
                // Update sceneData only if scenes have URLs to replace placeholders
                setSceneData((prevData) =>
                    prevData.map((scene, index) => {
                        const newScene = scenes[index];
                        return newScene && newScene.url && newScene.url !== scene.url
                            ? newScene // Replace placeholder with actual scene when URL arrives
                            : scene;
                    })
                );
            }
        }
    }, [scenes, sceneData.length, isWebSocket]);

    const handleRefresh = async (index) => {
        const newLoadingScenes = [...loadingScenes];
        newLoadingScenes[index] = true; // Set loading state to true
        setLoadingScenes(newLoadingScenes);

        const scene = sceneData[index];
        const newScene = await getScene(videoId, scene.id, modelId);
        if (newScene && newScene.scene.url) {
            const newSceneData = [...sceneData];
            newSceneData[index] = {
                ...newSceneData[index],
                url: newScene.scene.url,
                key: Date.now(), // Adding a unique key to force video re-render
            };
            setSceneData(newSceneData);
        } else {
            console.error('Failed to refresh scene');
        }

        newLoadingScenes[index] = false; // Set loading state to false
        setLoadingScenes(newLoadingScenes);
    };

    return (
        <div className="video-viewer">
            <div className="video-grid">
                {sceneData.map((scene, index) => (
                    <div key={scene.id} className="video-item">
                        {scene?.url ? (
                            <video key={scene.key || scene.id} width="100%" controls>
                                <source src={scene.url} type="video/mp4" />
                                Your browser does not support the video tag.
                            </video>
                        ) : (
                            <div className="loading-placeholder animate-pulse">Loading...</div>
                        )}

                        <div className="frame-caption-container">
                            <textarea
                                className="frame-caption"
                                value="Hit the refresh button to get a new animation based on this image."
                                readOnly
                                rows={3}
                            />
                            <button onClick={() => handleRefresh(index)} className="refresh-btn" disabled={loadingScenes[index]}>
                                <FaSync className={loadingScenes[index] ? 'spinning' : ''} />
                            </button>
                        </div>
                    </div>
                ))}
            </div>
            <div className="button-container">
                <button onClick={onBack} className="video-viewer-buttons">Modify Frames</button>
                <button onClick={onGenerateVideo} className="video-viewer-buttons">Generate Video</button>
            </div>
        </div>
    );
};

export default VideoViewer;