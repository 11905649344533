import React, { useEffect, useState } from "react";
import VideoCard from "./VideoCard"; // Reuse the existing VideoCard component
import { fetchUserVideos } from "../services/UserService"; // Fetch the videos from backend

const UserVideoList = ({ user, onVideoSelect }) => {
    const [userVideos, setUserVideos] = useState([]);

    useEffect(() => {
        // Fetch the user videos when the user is logged in
        if (user) {
            const getUserVideos = async () => {
                try {
                    const videos = await fetchUserVideos(user.uid); // Backend call to get user videos
                    setUserVideos(videos);
                } catch (error) {
                    console.error("Error fetching user videos:", error);
                }
            };

            getUserVideos();
        }
    }, [user]);

    if (!user) {
        return null; // If no user is logged in, don't render the component
    }

    return (
        <div className="relative max-h-[900px] w-81 flex flex-col items-center justify-start overflow-y-auto border rounded-lg shadow-lg bg-[#f4e2ff] p-4">
            <h3 className="text-lg font-bold text-yellow-600 text-center mb-4">Your Created Videos</h3>
            <div className="w-full h-full overflow-y-auto space-y-4 px-2">
                {userVideos.map((video) => {
                    // Extract the first frame URL from the frames object
                    const frames = Object.values(video.frames);
                    const firstFrameUrl = frames.length > 0 ? frames[0].url : null;

                    return (
                        <VideoCard
                            key={video.id}
                            {...video}
                            poster={firstFrameUrl}  // Pass the first frame URL as poster
                            onClick={() => onVideoSelect(video)}
                            className="m-2"
                        />
                    );
                })}
            </div>
        </div>
    );
};

export default UserVideoList;