import React, { useEffect, useState } from 'react';
import { loginAndStoreUser, logoutUser } from '../services/UserService'; // Assuming you have these functions in UserService.js

const Header = ({ user }) => {
    const [photoURL, setPhotoURL] = useState(user?.photoURL || '');

    useEffect(() => {
        // Cache the photoURL once it's retrieved to avoid multiple requests
        if (!photoURL && user && user.photoURL) {
            setPhotoURL(user.photoURL);
        }
    }, [user, photoURL]);

    const handleLogin = async () => {
        try {
            await loginAndStoreUser(); // Trigger login process
        } catch (error) {
            console.error("Login failed:", error);
        }
    };

    const handleLogout = async () => {
        try {
            await logoutUser(); // Trigger logout process
            window.location.reload(); // Optionally reload to clear state
        } catch (error) {
            console.error("Logout failed:", error);
        }
    };

    return (
        <header className="header py-4 bg-[#463ca7] text-white text-4xl font-extrabold">
            <div className="container mx-auto flex justify-between items-center">
                <h2>REELFY.AI</h2>
                {user ? (
                    <div className="flex items-center">
                        <img
                            src={photoURL}
                            alt={user.displayName}
                            className="w-10 h-10 rounded-full mr-2"
                            onError={(e) => e.target.src = '/fallback_user_avatar.png'} // Fallback image
                        />
                        <span className="mr-4">{user.displayName.split(" ")[0]}</span>
                        <button
                            onClick={handleLogout}
                            className="bg-white text-[#463ca7] px-3 py-1 rounded-full text-lg"
                        >
                            Logout
                        </button>
                    </div>
                ) : (
                    <button
                        onClick={handleLogin}
                        className="bg-white text-[#463ca7] px-2 py-1 rounded-full text-lg"
                    >
                    Login
                    </button>
                )}
            </div>
        </header>
    );
};

export default Header;