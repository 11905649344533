import React from 'react';

const VideoCard = ({ poster, onClick }) => {
    return (
        <div className="w-64 h-36 m-2 cursor-pointer" onClick={onClick}>
            <img src={poster} alt="Video thumbnail" className="w-full h-full object-cover rounded-lg" />
        </div>
    );
};

export default VideoCard;